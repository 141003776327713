<template>
  <div>
    <viewer
      :images="images"
      :options="options"
      class="viewer"
      ref="viewer"
      @inited="inited"
      v-if="images && images.length">
      <img
        v-for="(image, index) in images"
        :src="image"
        :key="index"
        class="image"
        id="vueImageViewer">
    </viewer>
  </div>
</template>

<script>
import Vue from 'vue'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
export default {
  name: 'ImageViewer',
  data () {
    return {
      options: {
        // url: 'data-source'
      },
      index: 0,
      images: []
    }
  },
  methods: {
    inited (viewer) {
      this.$viewer = viewer
      this.$viewer.view(this.index)
    },
    view (index) {
      this.index = index
      this.$viewer.view(this.index)
    },
    show (images) {
      this.images = images
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  display: none;
}
#vueImageViewer {
  display: none;
}
</style>
<style>
.viewer-loading > img {
  display: none; /* hide big images when it is loading */
}
</style>
